/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@1.11.1/dist/jquery.min.js
 * - /npm/jquery-mask-plugin@1.14.15/dist/jquery.mask.min.js
 * - /npm/bootstrap@3.3.1/dist/js/bootstrap.min.js
 * - /npm/suggestions-jquery@19.8.0/dist/js/jquery.suggestions.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
